<template>
  <div class="main-content">
    <div class="layout-px-spacing">
      <div class="layout-top-spacing">
        <div class="layout-spacing">
          <div class="mb-3 d-flex justify-content-between">
            <h3>Create New Incident</h3>
            <b-btn variant="primary" :to="{ name: 'Incident Reports' }">
              <i class="fa fa-arrow-left mr-1"></i>
              Go to Incidents
            </b-btn>
          </div>

          <div class="widget-content widget-content-area">
            <form
              method="POST"
              @submit.prevent="saveIncident()"
              @keydown="incidentForm.onKeydown($event)"
            >
              <AlertError :form="incidentForm" />

              <b-row>
                <b-col>
                  <b-form-group
                    description="Give the incident a subject"
                    label="Title *"
                    label-for="title"
                    :invalid-feedback="incidentForm.errors.get('title')"
                    :state="!incidentForm.errors.has('title')"
                  >
                    <b-form-input
                      id="title"
                      v-model="incidentForm.title"
                      placeholder="Enter a subject"
                      type="text"
                      min="100"
                      :state="incidentForm.errors.has('title') ? false : null"
                      trim
                      required
                    ></b-form-input>
                  </b-form-group>
                </b-col>

                <b-col>
                  <b-form-group
                    description="What category does this incident fall in?"
                    label="Category *"
                    label-for="category"
                    :invalid-feedback="incidentForm.errors.get('category')"
                    :state="!incidentForm.errors.has('category')"
                  >
                    <b-form-select
                      id="category"
                      v-model="incidentForm.category"
                      :options="categories"
                      :state="incidentForm.errors.has('category') ? false : null"
                      required
                    >
                      <template #first>
                        <b-form-select-option value="" disabled>-- Please select an option --</b-form-select-option>
                      </template>
                    </b-form-select>
                  </b-form-group>
                </b-col>
              </b-row>

              <b-form-group
                description="Describe the incident"
                label="Description"
                label-for="description"
                :invalid-feedback="incidentForm.errors.get('description')"
                :state="!incidentForm.errors.has('description')"
              >
                <b-form-textarea
                  id="description"
                  v-model="incidentForm.description"
                  placeholder="Enter your description"
                  rows="3"
                  :state="incidentForm.errors.has('description') ? false : null"
                  trim
                ></b-form-textarea>
              </b-form-group>

              <b-form-group
                description="Do you have any attachments to provide guide for the incident?"
                label="Attachments"
                label-for="attachments"
                :invalid-feedback="incidentForm.errors.get('attachments')"
                :state="!incidentForm.errors.has('attachments')"
              >
                <b-form-file
                  v-model="incidentForm.attachments"
                  :state="incidentForm.errors.has('attachments') ? false : null"
                  placeholder="Choose files or drop it here..."
                  drop-placeholder="Drop files here..."
                  accept="application/pdf, image/*"
                  :file-name-formatter="formatFileNames"
                  class="mb-3"
                  multiple
                ></b-form-file>
              </b-form-group>

              <b-form-group
                description="Departments to handle this incident"
                label="Department(s) to handle"
                label-for="dept_to_handle"
                :invalid-feedback="incidentForm.errors.get('dept_to_handle')"
                :state="!incidentForm.errors.has('dept_to_handle')"
              >
                <b-form-tags
                  input-id="dept_to_handle"
                  v-model="incidentForm.dept_to_handle"
                  separator=",;"
                  placeholder="Enter a department separated by space, comma or semicolon"
                  :state="incidentForm.errors.has('dept_to_handle') ? false : null"
                  add-on-change
                  remove-on-delete
                  no-outer-focus
                >
                  <template v-slot="{ tags, inputAttrs, inputHandlers, disabled, removeTag }">
                    <ul v-if="tags.length > 0" class="list-inline d-inline-block mb-2">
                      <li v-for="tag in tags" :key="tag" class="list-inline-item">
                        <b-form-tag
                          @remove="removeTag(tag)"
                          :title="tag"
                          :disabled="disabled"
                          variant="info"
                        >
                          {{ tag | startCase }}
                        </b-form-tag>
                      </li>
                    </ul>
                    <b-form-select
                      v-bind="inputAttrs"
                      v-on="inputHandlers"
                      :disabled="disabled || availableDepartments.length === 0"
                      :options="availableDepartments"
                    >
                      <template #first>
                        <!-- This is required to prevent bugs with Safari -->
                        <option disabled value="">Choose a department...</option>
                      </template>
                    </b-form-select>
                  </template>
                </b-form-tags>
              </b-form-group>

              <b-row>
                <b-col>
                  <b-form-group
                    description="What have you done in resolving this incident so far?"
                    label="Action Taken So Far"
                    label-for="actiontaken"
                    :invalid-feedback="incidentForm.errors.get('actiontaken')"
                    :state="!incidentForm.errors.has('actiontaken')"
                  >
                    <b-form-textarea
                      id="actiontaken"
                      v-model="incidentForm.actiontaken"
                      placeholder="Enter the steps taken"
                      rows="3"
                      :state="incidentForm.errors.has('actiontaken') ? false : null"
                      trim
                    ></b-form-textarea>
                  </b-form-group>
                </b-col>
                <b-col>
                  <b-form-group
                    description="What do you recommend?"
                    label="Recommendation"
                    label-for="recommendation"
                    :invalid-feedback="incidentForm.errors.get('recommendation')"
                    :state="!incidentForm.errors.has('recommendation')"
                  >
                    <b-form-textarea
                      id="recommendation"
                      v-model="incidentForm.recommendation"
                      placeholder="Enter your recommendation"
                      rows="3"
                      :state="incidentForm.errors.has('recommendation') ? false : null"
                      trim
                    ></b-form-textarea>
                  </b-form-group>
                </b-col>
              </b-row>

              <b-form-group
                description="When would you like to reminded of this incident again?"
                label="Date to Revisit"
                label-for="date_to_revisit"
                :invalid-feedback="incidentForm.errors.get('date_to_revisit')"
                :state="!incidentForm.errors.has('date_to_revisit')"
              >
                <b-form-datepicker
                  id="date_to_revisit"
                  v-model="incidentForm.date_to_revisit"
                  :state="incidentForm.errors.has('date_to_revisit') ? false : null"
                  :min="tomorrow"
                  locale="en"
                ></b-form-datepicker>
              </b-form-group>

              <b-button variant="success" type="submit">
                <b-spinner label="Loading" v-if="incidentForm.busy" small></b-spinner>
                Create Incident
              </b-button>
            </form>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { endpoints } from "@/utils/endpoints";
import { serialize } from "object-to-formdata";

export default {
  name: "NewIncident",
  data() {
    return {
      categories: [
        { text: 'Sponsor', value: 'sponsor' },
        { text: 'Elderly', value: 'elderly' },
        { text: 'Doctor', value: 'doctor' },
        { text: 'Other', value: 'other' }
      ],
      tomorrow: (new Date()).addDays(1),
      incidentForm: new this.$Form({
        title: "",
        category: "",
        description: "",
        dept_to_handle: [],
        actiontaken: "",
        recommendation: "",
        date_to_revisit: "",
        attachments: [],
      }),
      userRoles: this.$root.userRoles
    };
  },
  computed: {
    availableDepartments() {
      return this.userRoles.filter((role) => this.incidentForm.dept_to_handle.indexOf(role) === -1)
    }
  },
  methods: {
    formatFileNames(files) {
      return files.length + ' files selected (' + files.map((file) => file.name).join(', ') + ')';
    },
    saveIncident() {
      this.incidentForm
        .post(endpoints.CREATE_INCIDENT, {
          // Transform form data to FormData
          transformRequest: [
            function(data) {
              return serialize(data);
            }
          ],
        })
        .then((response) => {
          this.$toast.success('Incident created successfully');

          this.$router.push(`/incidents/view/${response.id}`);
        })
        .catch((error) => {
          this.$toast.error(error.response?.data?.message || error.response?.message);
        });
    }
  },
};
</script>
